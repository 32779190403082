import { isEmpty } from "./isEmpty";

export const encodeObjectToQuery = (
  queryObject = {},
  queryVarName = "state",
): string => {
  if (isEmpty(queryObject)) return "";
const queryString = btoa(JSON.stringify(queryObject));
  return `?${queryVarName}=${queryString}`;
};

export const decodeQueryData = (
  query: any = {},
  queryVariable = "state",
): any => {
  const ecodedQueryObj = (query[queryVariable] as string) || "";

  let decodedQueryObj = null;

  if (ecodedQueryObj) {
    try {
      const decodedString = atob(ecodedQueryObj)
      decodedQueryObj = JSON.parse(decodedString);
    } catch (error) {
      console.log("error in parsing", error);
    }
  }

  console.log("query is decodedQueryObj", query, decodedQueryObj);
  return decodedQueryObj;
};

export const encodeToBase64String = (variable: any) => {
  const queryString = btoa(JSON.stringify(variable));
  return queryString;
};

export const decodeFromBase64String = (encodedString: string) => {
  const decodedString = atob(encodedString);
  return decodedString;
};
