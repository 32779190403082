import router from "next/router";
import React from "react";
import { encodeObjectToQuery } from "../../../utils/urlQueryUtils";

const ListItem = ({ data }) => {
  const encodedQuery = encodeObjectToQuery({ deviceName: data?.name });
  const url = {
    pathname: `/devices/${data?.alias}`,
    query: encodedQuery.substring(1),
  };
  const goToDeviceDetails = () => {
    router.push(url);
  };
  return (
    <div
      onClick={goToDeviceDetails}
      className="w-full p-5 bg-[#F9F9FA] dark:bg-[#090C13] border border-custom-50 dark:border-[#202431] rounded-md flex flex-row items-center dark:hover:bg-[#15181D] hover:bg-[#EFF4F9] cursor-pointer"
    >
      <div className="w-14 h-14 flex items-center justify-center rounded-lg hover:border border-transparent overflow-hidden">
        <img alt="device-icon" src={data?.displayIconMediaPath} />
      </div>
      <div className="flex flex-col ml-4">
        <p className="text-xl text-[#33394E] dark:text-storm-dust-100 font-medium">
          {data?.name}
        </p>
        <p className="text-sm text-custom-410 dark:text-custom-300">
          Alias: {data?.alias}
        </p>
      </div>
    </div>
  );
};

export default ListItem;
