import React from "react";

const SlackIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="62"
    height="60"
    viewBox="0 0 62 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.3128 29.0144C33.5614 29.0144 31.3229 26.8039 31.3229 24.0869V12.2608C31.3229 9.54374 33.5614 7.33325 36.3128 7.33325C39.0643 7.33325 41.3028 9.54374 41.3028 12.2608V24.0869C41.3028 26.8039 39.0643 29.0144 36.3128 29.0144ZM48.2887 29.0144H44.2967C43.7448 29.0144 43.2987 28.5739 43.2987 28.0289V24.0869C43.2987 21.3698 45.5372 19.1593 48.2887 19.1593C51.0402 19.1593 53.2786 21.3698 53.2786 24.0869C53.2786 26.8039 51.0402 29.0144 48.2887 29.0144ZM24.3369 29.0144H12.361C9.60959 29.0144 7.37109 26.8039 7.37109 24.0869C7.37109 21.3698 9.60959 19.1593 12.361 19.1593H24.3369C27.0884 19.1593 29.3269 21.3698 29.3269 24.0869C29.3269 26.8039 27.0884 29.0144 24.3369 29.0144ZM28.3289 17.1883H24.3369C21.5855 17.1883 19.347 14.9778 19.347 12.2608C19.347 9.54374 21.5855 7.33325 24.3369 7.33325C27.0884 7.33325 29.3269 9.54374 29.3269 12.2608V16.2028C29.3269 16.7478 28.8808 17.1883 28.3289 17.1883ZM24.3369 52.6666C21.5855 52.6666 19.347 50.4561 19.347 47.739V35.913C19.347 33.1959 21.5855 30.9854 24.3369 30.9854C27.0884 30.9854 29.3269 33.1959 29.3269 35.913V47.739C29.3269 50.4561 27.0884 52.6666 24.3369 52.6666ZM12.361 40.8405C9.60959 40.8405 7.37109 38.63 7.37109 35.913C7.37109 33.1959 9.60959 30.9854 12.361 30.9854H16.353C16.9049 30.9854 17.351 31.4259 17.351 31.9709V35.913C17.351 38.63 15.1125 40.8405 12.361 40.8405ZM48.2887 40.8405H36.3128C33.5614 40.8405 31.3229 38.63 31.3229 35.913C31.3229 33.1959 33.5614 30.9854 36.3128 30.9854H48.2887C51.0402 30.9854 53.2786 33.1959 53.2786 35.913C53.2786 38.63 51.0402 40.8405 48.2887 40.8405ZM36.3128 52.6666C33.5614 52.6666 31.3229 50.4561 31.3229 47.739V43.797C31.3229 43.252 31.769 42.8115 32.3209 42.8115H36.3128C39.0643 42.8115 41.3028 45.022 41.3028 47.739C41.3028 50.4561 39.0643 52.6666 36.3128 52.6666Z"
      fill="currentColor"
    />
  </svg>
);

export default SlackIcon;
