import WithLayout from "../src/components/Hoc/withLayout";
import ConsoleLandingPage from "../src/components/ConsoleHome";
import authRoute from "../src/components/Authentication/authRoute";
import { useStore } from "../src/storeManager/stores";
import { useEffect } from "react";
import { useRouter } from "next/router";

const IndexPage = () => {
  const { loggedInUser } = useStore().authStore;
  const router = useRouter();
  useEffect(() => {
    if (!loggedInUser) router.push("/auth/signin");
  }, []);
  return <ConsoleLandingPage />;
};

export default authRoute(WithLayout(IndexPage, "Console Home"));
