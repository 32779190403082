import React from "react";
import { Button } from "@mecha/design-components/dist/src/components/Elements/Button";
import { useRouter } from "next/router";
const NoDevices = () => {
  const router = useRouter();
  return (
    <div className="p-10 dark:bg-[#090C13] bg-[#F9F9FA] h-max w-full mt-4 border dark:border-[#202431] rounded-lg flex flex-col justify-center items-center">
      <div className="w-[94px] h-[72px]">
        <img
          alt="no-devices-img"
          src="/assets/img/no-device-image.png"
          className="object-cover h-full"
        />
      </div>
      <p className="text-base  text-[#33394E] dark:text-storm-dust-100 font-bold font-gosha-sans mt-2">
        Connect a device
      </p>
      <p className="text-sm text-custom-410 dark:text-custom-70 mt-2 text-center">
        No devices found under your account.
      </p>
      <Button
        onClick={() => {
          router.push("/devices");
        }}
        color="primary"
        className="mt-4"
      >
        Connect device
      </Button>
    </div>
  );
};

export default NoDevices;
