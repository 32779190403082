import React from "react";
import SlackIcon from "../../../public/assets/svg/SlackIcon";
import RedditIcon from "../../../public/assets/svg/RedditIcon";
import DiscordIcon from "../../../public/assets/svg/DiscordIcon";
import GitHubIcon from "../../../public/assets/svg/GitHubIcon";
import Link from "@mecha/design-components/dist/src/components/Elements/Link";
import UrlIcon from "../../../public/assets/svg/UrlIcon";

const ResourcesItem = ({ data }) => {
  return (
    <div className="flex flex-col w-full gap-1">
      <a
        href={data.href}
        className={`w-full aspect-square rounded-lg border border-[#E4E7EE] dark:border-[#202431] flex items-center justify-center ${data.hoverBgColor}`}
      >
        {data.icon}
      </a>
      <p className="text-base font-medium dark:text-storm-dust-100 text-[#33394E] mt-2">
        {data.title}
      </p>
      <p className="text-[#33394E] dark:text-custom-300 text-custom-410 text-sm">
        {data.subject}
      </p>
      <span className="flex flex-row justify-between items-center mt-2">
        <Link
          color="primary"
          className="flex items-center"
          href={data.href}
          rightIcon={<UrlIcon className="ml-1 h-[9px] w-[9px]" />}
        >
          {data.link}
        </Link>
      </span>
    </div>
  );
};

const Resources = () => {
  const ResourcesData = [
    {
      icon: <DiscordIcon className="dark:text-white text-neutral-150" />,
      title: "Discord channel",
      subject: "Join, talk, discuss what’s happening at Mecha",
      link: "blogs.mecha",
      href: "#",
      hoverBgColor: " hover:bg-grape-800",
    },
    {
      icon: <RedditIcon className="dark:text-white text-neutral-150" />,
      title: "Mecha Reddit community",
      subject: "Community of mecha users",
      link: "reddit/mecha",
      href: "#",
      hoverBgColor: " hover:bg-mecha-red-800",
    },
    {
      icon: (
        <GitHubIcon
          width={35}
          height={35}
          className="dark:text-white text-neutral-150"
        />
      ),
      title: "Mecha @github",
      subject: "Apps, cloud and developers/ mecha",
      link: "github/mecha",
      href: "#",
      hoverBgColor: " hover:bg-mecha-gray-100",
    },
    {
      icon: <SlackIcon className="dark:text-white text-neutral-150" />,
      title: "Join us at Slack",
      subject: "A Slack channel for our Hacker edition experience",
      link: "slack-mecha",
      href: "#",
      hoverBgColor: " hover:bg-custom-100",
    },
  ];

  return (
    <div>
      <div className="w-full  mb-10">
        <div className="w-full flex flex-row justify-between items-center">
          <p className="font-bold text-xl dark:text-custom-300 text-custom-310">
            Resources
          </p>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mt-4 lg:flex lg:flex-row lg:justify-between">
          {ResourcesData.map((value, idx) => (
            <ResourcesItem data={value} key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Resources;
