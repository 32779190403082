const NewsItem = ({ data }) => {
  return (
    <a
      href={data.url}
      target="_blank"
      className="flex flex-row"
      rel="noreferrer"
    >
      <div className="rounded-md overflow-hidden">
        <img
          alt="feature-img"
          src={data.feature_image || "/assets/img/blog-icon.png"}
          className="object-cover h-[72px] w-[72px]"
          // style={{ height: "inherit" }}
        />
      </div>
      <div className="ml-4 flex flex-col justify-start gap-2">
        <p className="text-base text-[#33394E] dark:text-storm-dust-100 font-gosha-sans">
          {data?.title?.length > 30
            ? data.title.slice(0, 30) + "..."
            : data?.title}
        </p>
        <div className="flex flex-row items-center ">
          <span className="h-[20px] w-[20px] flex">
            {data?.primary_author?.profile_image ? (
              <img
                alt="author-avatar"
                src={data?.primary_author?.profile_image}
                className="h-[20px] w-[20px] rounded-sm"
              />
            ) : (
              <span className="h-[20px] w-[20px] flex items-center justify-center rounded-sm text-white font-bold bg-custom-600 text-xs">
                {data?.primary_author?.name.slice(0, 1).toUpperCase()}
              </span>
            )}
          </span>
          <p className="text-base text-custom-410 dark:text-custom-300 text-sm ml-1">
            {data?.primary_author?.name}
          </p>
        </div>
      </div>
    </a>
  );
};

export default NewsItem;
