import React, { useEffect, useState } from "react";
import UrlIcon from "../../../public/assets/svg/UrlIcon";
import TalkItem from "./WhatsNewItems/TalkItem";
import CheckCircleIcon from "../../../public/assets/svg/CheckCircleIcon";
import NewsItem from "./WhatsNewItems/NewsItem";
import Logger from "../../libs/Logger";
import { ErrorReporter } from "../../libs/ErrorReporter";
import { Link } from "@mecha/design-components/dist/src/components/Elements/Link";
import { getBlogPosts } from "../../api/blogPostsApis";
import { getForumTopics } from "../../api/forumTopicsApis";
import { BLOG_HOST_URL } from "../../config/envConstants";
const WhatsNew = () => {
  const log = new Logger({ module: "Console", component: "Blogs" });
  const [featuredBlogPost, setFeaturedBlogPost] = useState([]);
  const [blogPost, setBlogPost] = useState([]);
  const [forumTopic, setForumTopic] = useState([]);
  const fetchBlogPosts = async () => {
    const res = await getBlogPosts(
      "order=published_at%20DESC&filter=featured:false&limit=3&include=tags,authors",
    ).catch(error => {
      log.error({ error: error }, "(blogs) : error fetching blogs from ghost");
      ErrorReporter.collect(error, "Error in fetching Blogs from Ghost.");
    });
    setBlogPost(res?.posts || []);
  };
  const fetchFeaturedBlogPosts = async () => {
    const res = await getBlogPosts(
      "&order=published_at%20DESC&filter=featured:true&limit=1&include=tags,authors",
    ).catch(error => {
      log.error({ error: error }, "(blogs) : error fetching blogs from ghost");
      ErrorReporter.collect(error, "Error in fetching Blogs from Ghost.");
    });
    setFeaturedBlogPost(res?.posts || []);
  };
  const fetchForumTopic = async () => {
    const res = await getForumTopics().catch(error => {
      log.error(
        { error: error },
        "(forums) : Error in fetching topics from discourse.",
      );
      ErrorReporter.collect(error, "Error in fetching Topics from discrouse.");
    });
    setForumTopic(res?.topic_list?.topics || []);
  };
  useEffect(() => {
    fetchBlogPosts();
    fetchFeaturedBlogPosts();
    fetchForumTopic();
  }, []);
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="dark:bg-[#090C13] bg-[#F9F9FA] h-max max-w-[440px] p-5  border dark:border-[#202431] rounded-lg">
        {/* Title */}
        <span className="flex flex-row justify-between items-center">
          <p className="text-xl dark:text-storm-dust-100 text-[#33394E] font-bold">
            What&apos;s new
          </p>
          <Link
            color="primary"
            target="_blank"
            className="flex items-center"
            href={BLOG_HOST_URL}
            rightIcon={<UrlIcon className="ml-1 h-[9px] w-[9px]" />}
          >
            blogs.mecha
          </Link>
        </span>
        {/* Banner */}
        <a
          target="_blank"
          href={featuredBlogPost[0]?.url}
          className="flex flex-row mt-5 dark:bg-[#05070A] bg-white rounded-lg border-2 border-[#3283E8] p-4"
          rel="noreferrer"
        >
          <div>
            <p className="font-bold font-gosha-sans text-xl text-[#3951D4]">
              {featuredBlogPost[0]?.title?.length > 14
                ? featuredBlogPost[0]?.title.slice(0, 14) + "..."
                : featuredBlogPost[0]?.title}
            </p>
            <p
              className="text-xs mt-2 dark:text-custom-20 text-[#33394e] font-normal text-wrap"
              // dangerouslySetInnerHTML={{ __html: featuredBlogPost[0]?.html }}
            >
              {featuredBlogPost[0]?.excerpt?.length > 30
                ? `${featuredBlogPost[0]?.excerpt.slice(0, 50)}...`
                : featuredBlogPost[0]?.excerpt}
            </p>
          </div>
          <div className="flex-1">
            <img
              alt="featured-blog"
              src={featuredBlogPost[0]?.feature_image}
              className="w-full h-full ml-2 min-w-[224px] max-w-[224px] min-h-[135px] max-h-[135px] object-cover"
            />
          </div>
        </a>
        {/* News List */}
        <div className="mt-5 flex flex-col gap-5">
          {blogPost.map((value, idx) => {
            return <NewsItem data={value} key={idx} />;
          })}
        </div>
      </div>
      {/* Talks section */}
      <div className="dark:bg-[#090C13] border dark:border-[#202431] rounded-lg p-5">
        <span className="flex flex-row justify-between items-center">
          <p className="text-base dark:text-custom-300 text-[#33394E] font-medium">
            Talks
          </p>
          <Link
            color="primary"
            className="flex items-center"
            href="#"
            rightIcon={<UrlIcon className="ml-1 h-[9px] w-[9px]" />}
          >
            forums-mecha
          </Link>
        </span>
        <TalkItem data={forumTopic} />
      </div>
      {/* Status Section */}
      <div className="dark:bg-[#090C13] border dark:border-[#202431] rounded-lg p-5">
        <span className="flex flex-row justify-between items-center">
          <p className="text-base dark:text-custom-300 text-[#33394E] font-medium">
            Status
          </p>
        </span>
        <div className="mt-4 flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <CheckCircleIcon className="text-[#34C759]" />
            <p className="text-[#33394E] dark:text-storm-dust-100 font-medium ml-1">
              All systems Operational
            </p>
          </div>
          <Link
            color="primary"
            className="flex items-center"
            href="#"
            rightIcon={<UrlIcon className="ml-1 h-[9px] w-[9px]" />}
          >
            status.mecha
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhatsNew;
