import React, { useEffect } from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import NoDevices from "./NoDevices";
import { useStore } from "../../../storeManager/stores";
import { observer } from "mobx-react";
import ListItem from "../shared/ListItem";
import Loader from "../../../../public/assets/svg/LoaderIcon";
const DeviceList = () => {
  const { devicesList, getAllDevices, setLimit, loading } =
    useStore().deviceStore;
  setLimit(3);
  useEffect(() => {
    getAllDevices();
  }, []);

  return (
    <div className="w-full mb-10">
      <div className="w-full flex flex-row justify-between items-center">
        <p className="font-bold text-xl dark:text-custom-300 text-custom-310">
          Devices
        </p>
        <a href="/devices">
          <ArrowRightIcon
            height={18}
            width={18}
            className="text-neutral-110 dark:text-storm-dust-100 __navigate-devices"
          />
        </a>
      </div>
      {loading ? (
        <div
          className="flex justify-center items-center h-44 w-full border dark:border-[#202431] rounded-lg mt-4"
          style={{ height: "200px" }}
        >
          <Loader className="text-primary-500 w-10 h-10 inline animate-spin" />
        </div>
      ) : devicesList.length > 0 ? (
        <div className="mt-4 grid grid-cols-1 gap-5 lg:grid-cols-2 justify-between flex-wrap">
          {devicesList.map((item, idx) => (
            <ListItem key={idx} data={item} />
          ))}
        </div>
      ) : (
        <NoDevices />
      )}
    </div>
  );
};

export default observer(DeviceList);
