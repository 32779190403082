import React from "react";

const CommentIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0835 9.66663C15.0835 10.5871 14.3373 11.3333 13.4169 11.3333H8.41687L4.25021 14.6666V11.3333H2.58354C1.66307 11.3333 0.916875 10.5871 0.916875 9.66663V2.99996C0.916875 2.07949 1.66307 1.33329 2.58354 1.33329H13.4169C14.3373 1.33329 15.0835 2.07949 15.0835 2.99996V9.66663Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CommentIcon;
