import CommentIcon from "../../../../public/assets/svg/CommectIcon";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { FORUMS_HOST_URL } from "../../../config/envConstants";
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(relativeTime);
const TalkItem = ({ data }) => {
  const latestTopic = data[1];
  const forumUrl = `${FORUMS_HOST_URL}/t/${latestTopic?.slug}`;
  return (
    <a
      href={forumUrl}
      target="_blank"
      className="flex flex-row mt-4"
      rel="noreferrer"
    >
      <div className="rounded-md overflow-hidden">
        <img
          alt="forum-banner"
          src={latestTopic?.image_url || "/assets/img/forum-icon.jpg"}
          className="object-cover min-h-[72px] min-w-[72px] max-h-[72px] max-w-[72px]"
          // style={{ height: "inherit" }}
        />
      </div>
      <div className="ml-4 flex flex-col justify-center">
        <p className="text-base text-[#33394E] dark:text-storm-dust-100 font-gosha-sans">
          {latestTopic?.title.length > 30
            ? latestTopic.title.slice(0, 40) + "..."
            : latestTopic?.title}
        </p>
        <div className="mt-1 flex flex-row items-center justify-between">
          <span className="flex flex-row items-center">
            <CommentIcon className="text-custom-410 dark:text-custom-300" />
            <p className="text-custom-410 dark:text-custom-300 text-sm ml-1">
              {latestTopic?.posts_count}
            </p>
          </span>
          <p className="dark:text-custom-300 text-custom-410 text-sm ml-1">
            {dayjs(latestTopic?.last_posted_at).fromNow()}
          </p>
        </div>
      </div>
    </a>
  );
};

export default TalkItem;
