import React from "react";
import DeviceList from "./DeviceList";
import Resources from "./Resources";
import WhatsNew from "./WhatsNew";
const ConsoleLandingPage = () => {
  return (
    <div className="w-full gap-5 dark:bg-dark min-h-screen 2xl:container p-5 2xl:mx-auto flex justify-between lg:flex-nowrap">
      <div className="flex flex-row gap-10 lg:container mx-auto">
        <div className="w-full">
          <DeviceList />
          <Resources />
        </div>
        <div className="hidden md:flex justify-end md:min-w-[440px]">
          <WhatsNew />
        </div>
      </div>
    </div>
  );
};

export default ConsoleLandingPage;
